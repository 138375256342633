import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../layouts/MainLayout'
import SinglePageLayout from '../layouts/SinglePageLayout'
import Home from '../components/Home'
import Order from '../components/Order'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [{path: '', component: Home}]
  },
  {
    path: '/order/:id?',
    component: SinglePageLayout,
    children: [{path: '', component: Order}]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
