<template>
  <v-container fluid>
    <v-container>
      <v-row class="grey--text text--darken-3" :class="{'mt-10': this.$vuetify.breakpoint.mdAndUp}">
        <v-col
          v-scrollanimation
          align="center"
        >
          <span
            class="font-weight-bold"
            :class="{
                'text-h4':this.$vuetify.breakpoint.xs,
                'text-h2':this.$vuetify.breakpoint.sm, 
                'text-h1':this.$vuetify.breakpoint.mdAndUp
              }"
          >Four </span>
          <span
            class="font-weight-light"
            :class="{
                'text-h4':this.$vuetify.breakpoint.xs,
                'text-h2':this.$vuetify.breakpoint.sm, 
                'text-h1':this.$vuetify.breakpoint.mdAndUp
              }"
          >ingredients.</span> <br />
          <span
            class="font-weight-light"
            :class="{
                'text-h5':this.$vuetify.breakpoint.xs,
                'text-h3':this.$vuetify.breakpoint.sm, 
                'text-h2':this.$vuetify.breakpoint.mdAndUp
              }"
          >Infinite health benefits.</span> <br />
          <span
            class="font-weight-thin"
            :class="{
                'text-h5':this.$vuetify.breakpoint.xs,
                'text-h3':this.$vuetify.breakpoint.sm, 
                'text-h2':this.$vuetify.breakpoint.mdAndUp
              }"
          >For everyone.</span>
        </v-col>
      </v-row>
      <v-row :class=" this.$vuetify.breakpoint.xs? 'mx-5 mb-5 mt-0' : 'mx-15 mb-15 mt-0'">
        <v-col align="center">
          <v-img
            v-scrollanimation
            src="../assets/bread-sketch.png"
            alt="four-ingredient-bread"
            max-width="800"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-img
      v-scrollanimation
      class="carousel"
      :class=" isMobile? '' : 'mt-10 mb-10'"
      src="../assets/bread-carousel.jpg"
      alt="bread-carousel"
    />
    <v-container style="max-width: 1200px">
      <v-timeline
        v-scrollanimation
        :reverse="this.$vuetify.breakpoint.xs? false : true "
        :dense="this.$vuetify.breakpoint.xs? true : false "
        :align-top="isMobile? true : false "
      >
        <!-- ingredient water -->
        <v-timeline-item
        v-scrollanimation
          :small="isMobile? true : false"
        >
          <span :class="isMobile ? mobileHeaderClass : headerClass"> Our water </span> <br />
          <span :class="isMobile ? mobileSubheaderClass : subheaderClass">100% purified</span> <br /> <br />
          <span :class="isMobile ? mobileBodyClass : bodyClass"> Did you know, most breads are made up of 50% flour and </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">50% water. </span>
          <span :class="isMobile ? mobileBodyClass : bodyClass">That’s a lot of water.</span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">We use </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">100% purified water</span>
          <span :class="isMobile ? mobileBodyClass : bodyClass"> extracted through </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">“distillation”. </span>
          <span :class="isMobile ? mobileBodyClass : bodyClass">This way, only the good stuff enters your gut.</span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">That means no Fluoride, no Lead, no Chlorine, no microplastics, or any other nasty stuff.</span>
        </v-timeline-item>

        <!-- ingredient starter -->
        <v-timeline-item
        v-scrollanimation
          :small="isMobile? true : false"
        >
          <span :class="isMobile ? mobileHeaderClass : headerClass"> Our starter </span> <br />
          <span :class="isMobile ? mobileSubheaderClass : subheaderClass">100% natural wild bacteria</span> <br /> <br />
          <span :class="isMobile ? mobileBodyClass : bodyClass"> Think of “starter” as the natural version of store-bought yeast. </span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">It’s made up of </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">natural wild yeast and bacteria </span>
          <span :class="isMobile ? mobileBodyClass : bodyClass">found in our environment. </span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">We grow and cultivate our own starter from just two simple ingredients: </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">flour </span>
          <span :class="isMobile ? mobileBodyClass : bodyClass">and </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">purified water.</span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">Since chlorine found in tap water may kill the </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">good bacteria </span>
          <span :class="isMobile ? mobileBodyClass : bodyClass">in starter, we use purified water only.</span>
        </v-timeline-item>

        <!-- ingredient flour -->
        <v-timeline-item
        v-scrollanimation
          :small="isMobile? true : false"
        >
          <span :class="isMobile ? mobileHeaderClass : headerClass"> Our flour </span> <br />
          <span :class="isMobile ? mobileSubheaderClass : subheaderClass">70% wholemeal</span> <br /> <br />
          <span :class="isMobile ? mobileBodyClass : bodyClass"> Wholemeal is more nutrituous than white flour because it contains all parts of the grain kernel. </span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass"> We highly value nutrition and hence our bread boasts a staggering </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold"> 70% wholemeal.</span><br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass"> It has a beautiful rich taste thanks to the combination of every ingredient as a whole. </span>
        </v-timeline-item>

        <!-- ingredient salt -->
        <v-timeline-item
        v-scrollanimation
          :small="isMobile? true : false"
        >
          <span :class="isMobile ? mobileHeaderClass : headerClass"> Our salt </span> <br />
          <span :class="isMobile ? mobileSubheaderClass : subheaderClass">less than 2%</span> <br /> <br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">To make sure you’re in control of your sodium intake, our bread includes less than 2% salt to flour ratio. </span> <br /><br />
          <span :class="isMobile ? mobileBodyClass : bodyClass">We use </span>
          <span :class="isMobile ? mobileBodyClassBold : bodyClassBold">iodised salt </span>
          <span :class="isMobile ? mobileBodyClass : bodyClass">for the extra health benefits of iodine.</span>
        </v-timeline-item>
      </v-timeline>
    </v-container>
    <OrderForm />
  </v-container>
</template>

<script>
import OrderForm from './OrderForm';

export default {
  name: 'Home',

  components: {
    OrderForm,
  },

  data: () => ({
    headerClass: "text-h4 font-weight-medium",
    subheaderClass: "text-h5 font-weight-light font-italic",
    bodyClass: "text-h6 font-weight-light",
    bodyClassBold: "text-h6 font-weight-bold",
    // mobile text classes
    mobileHeaderClass: "text-h5 font-weight-medium",
    mobileSubheaderClass: "text-h6 font-weight-light font-italic",
    mobileBodyClass: "font-weight-light",
    mobileBodyClassBold: "font-weight-bold",
    show: false,
  }),

  computed: {
    isMobile() {
      return (this.$vuetify.breakpoint.smAndDown)
    }
  }
}
</script>

<style scoped>
.before-enter {
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease-out;
}
.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
