<template>
  <div>
    <!-- class="mx-auto pt-15 mb-10" -->
    <v-sheet
      v-scrollanimation
      fluid
      id="order"
      color="#F9F7F8"
      :height=" isMobile ? 100 : 150 "
      :class=" isMobile ? 'pa-8' : 'pa-15'"
      align="center"
    >
      <span
        class="font-weight-thin"
        :class="isMobile ? 'text-h5' : 'text-h4 '"
      >{{ isMobile ? 'ORDER TODAY':'PLACE YOUR ORDER TODAY'}}</span>
    </v-sheet>

    <v-container style="max-width: 1200px">
      <v-row>
        <v-col>
          <!-- card starts -->
          <v-card
            v-scrollanimation
            outlined
            :class=" isMobile? '':'pa-10'"
          >
            <v-container>
              <v-row>
                <div :class=" this.$vuetify.breakpoint.xs? '':'d-flex flex-row'">
                  <!-- left ----------------------------------------------------------------------------->
                  <v-col>
                    <span class="text-h5">Contact information</span>

                    <div class="d-flex flex-row align-center mt-3">
                      <v-text-field
                        v-model="form.first"
                        dense
                        class="mr-1"
                        outlined
                        label="First Name"
                        placeholder="First Name"
                        hide-details
                      ></v-text-field>
                      <v-text-field
                        v-model="form.last"
                        dense
                        class="ml-1"
                        outlined
                        label="Last Name"
                        placeholder="Last Name"
                        hide-details
                      ></v-text-field>
                    </div>

                    <v-text-field
                      v-model="form.email"
                      dense
                      outlined
                      label="Email address"
                      placeholder="Email address"
                      hide-details
                      class="mt-3 mb-3"
                    ></v-text-field>
                    <v-text-field
                      v-model="form.mobile"
                      dense
                      outlined
                      label="Mobile Number"
                      placeholder="Mobile Number"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model="form.deliveryStreet"
                      dense
                      class="mb-3 mt-3"
                      outlined
                      label="Street Address"
                      hide-details
                    ></v-text-field>

                    <div class="d-flex flex-row align-center">
                      <v-text-field
                        v-model="form.deliverySuburb"
                        dense
                        outlined
                        label="Suburb"
                        class="mr-1"
                        hide-details
                      ></v-text-field>

                      <v-text-field
                        v-model="form.deliveryPostCode"
                        dense
                        outlined
                        label="Postal code"
                        class="ml-1"
                        hide-details
                      ></v-text-field>
                    </div>

                    <v-divider class="mb-5 mt-5"></v-divider>

                    <span class="text-h5">Delivery method</span>
                    <v-radio-group
                      mandatory
                      v-model="form.deliveryMethod"
                      row
                    >
                      <v-radio
                        label="Pick-up"
                        value="pickup"
                      ></v-radio>
                      <v-radio
                        label="Delivery"
                        value="delivery"
                      ></v-radio>
                    </v-radio-group>

                    <v-card
                      v-if="form.deliveryMethod === 'pickup' "
                      outlined
                      class="mb-5"
                    >
                      <v-card-text>
                        <span class="font-weight-bold">FREE</span>
                        pick-up option 7 days a week from: <br />
                        23A George Street, North Strathfield, NSW 2137
                      </v-card-text>
                    </v-card>

                    <v-card
                      v-if="form.deliveryMethod === 'delivery' "
                      outlined
                      class="mb-5"
                    >
                      <v-card-text>
                        <span class="font-weight-bold">${{deliveryPrice}} flat per order </span>
                        is charged for every delivery (Sydney only). <br />
                        Delivery is only available on Saturday and Sunday.
                      </v-card-text>
                    </v-card>

                    <v-divider class="mb-5 mt-5"></v-divider>

                    <div>
                      <span
                        class="text-h5"
                        v-if="form.deliveryMethod === 'pickup'"
                      >Pick-up Date</span>
                      <span
                        class="text-h5"
                        v-if="form.deliveryMethod === 'delivery'"
                      >Delivery Date</span>
                      <br />
                      <div class="mt-3 mb-3 grey--text text--darken-2">
                        Please place your oder at least
                        <span class="font-weight-bold">3 days in advance.</span> <br />
                        <span v-if="form.deliveryMethod === 'pickup'">Pick-up is available 7 days a week.</span>
                        <span v-if="form.deliveryMethod === 'delivery'">Delivery is only available on Saturday and Sunday.</span>
                      </div>
                    </div>

                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          v-model="form.date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          hide-details
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="form.date"
                        :allowed-dates="allowedDates"
                        @input="menu = false"
                        :min="minDate"
                      ></v-date-picker>
                    </v-menu>
                    <span
                      class="text-caption red--text"
                      v-if="isOneLeft"
                    >Only one bread left on this day!</span>
                    <span
                      class="text-caption orange--text"
                      v-else
                    >Two breads available on this day.</span>

                    <v-divider class="mb-5 mt-5"></v-divider>

                    <span class="text-h5">Payment method</span>
                    <v-radio-group
                      mandatory
                      v-model="form.paymentMethod"
                      row
                    >
                      <v-radio
                        label="Transfer"
                        value="transfer"
                      ></v-radio>
                      <v-radio
                        label="Cash"
                        value="cash"
                      ></v-radio>
                    </v-radio-group>

                    <v-card
                      v-if="form.paymentMethod === 'transfer' "
                      outlined
                      class="mb-5"
                    >
                      <v-card-text>
                        Please transfer
                        <span class="font-weight-bold">{{ totalCost }}</span>
                        to the following PayID:
                        <span class="font-weight-bold">0410967625</span> <br />
                        We'll confirm your payment and match it against your order.
                      </v-card-text>
                    </v-card>

                    <v-card
                      v-if="form.paymentMethod === 'cash' "
                      outlined
                      class="mb-5"
                    >
                      <v-card-text>
                        Please have exact change of
                        <span class="font-weight-bold">{{totalCost}}</span>
                        ready on
                        <span v-if="form.deliveryMethod === 'delivery' ">delivery</span>
                        <span v-if="form.deliveryMethod === 'pickup' ">pick-up</span>
                        day.
                      </v-card-text>
                    </v-card>

                  </v-col>

                  <!-- right ----------------------------------------------------------------------------->
                  <v-col>
                    <span class="text-h5">Order Summary</span>
                    <v-row class="mt-1">
                      <v-col :cols="isMobile ? 5 : 3">
                        <v-img
                          src="../assets/bread-item.svg"
                          alt="bread-item"
                          contain
                          width="120"
                        >
                        </v-img>
                      </v-col>
                      <template v-if="!isMobile">
                        <v-col>
                          <span>Sourdough (700g)</span> <br />
                          <span class="font-weight-light grey--text text--darken-2">Ingredients: water, 70% wholemeal flour, 30% white flour, starter, salt</span>
                          <br /> <br /> <br />
                          <span class="font-weight-bold">{{formatCurrency(form.quantity * price)}}</span>
                        </v-col>
                        <v-col
                          cols="2"
                          class="d-flex align-end"
                        >
                          <v-text-field
                            v-model="form.quantity"
                            outlined
                            label="qty"
                            type="number"
                            min="1"
                            max="2"
                            hide-details
                            dense
                          ></v-text-field>
                        </v-col>
                      </template>

                      <template v-else>
                        <v-col>
                          <div>
                            <span>Sourdough (700g)</span> <br />
                            <span class="font-weight-light grey--text text--darken-2">Ingredients: water, 70% wholemeal flour, 30% white flour, starter, salt</span>
                          </div>
                          <br />
                          <div class="d-flex align-end">
                            <span
                              class="font-weight-bold"
                              style="width:50%"
                            >{{formatCurrency(form.quantity * price)}}</span>
                            <v-text-field
                              v-model="form.quantity"
                              outlined
                              label="qty"
                              type="number"
                              min="1"
                              max="2"
                              hide-details
                              dense
                            ></v-text-field>
                          </div>
                        </v-col>
                      </template>

                    </v-row>
                    <v-divider class="mt-5 mb-5"></v-divider>
                    <v-row>
                      <v-col>
                        <!-- <span class="text-h5">Confirm Order</span> -->
                        <div class="d-flex flex-row align-center font-weight-light mt-1">
                          <span style="width: 50%">Subtotal</span>
                          <span
                            style="width: 50%"
                            class="text-right"
                          >
                            {{formatCurrency(form.quantity * price)}}
                          </span>
                        </div>
                        <div class="d-flex flex-row align-center font-weight-light mt-3">
                          <span style="width: 50%"> Delivery</span>
                          <span
                            style="width: 50%"
                            class="text-right"
                          >
                            {{ deliveryCost }}
                          </span>
                        </div>

                        <v-divider class="mt-5 mb-5"></v-divider>

                        <div class="d-flex flex-row align-center font-weight-regular mt-3">
                          <span style="width: 50%"> Total</span>
                          <span
                            style="width: 50%"
                            class="text-right"
                          >
                            {{ totalCost }}
                          </span>
                        </div>

                        <v-divider class="mt-5 mb-5"></v-divider>
                        <div class="d-flex flex-row">
                          <v-text-field
                            v-model="coupon"
                            outlined
                            label="Coupon Code"
                            hide-details
                            dense
                            class="mr-2"
                          ></v-text-field>
                          <v-btn
                            color="primary"
                            dense
                            @click="checkCoupon"
                          >Submit</v-btn>
                        </div>
                        <span
                          v-show="isCouponEntered"
                          class="text-caption"
                          :class="isCouponValid? 'orange--text': 'red--text'"
                        >{{isCouponValid? 'Success!': 'Invalid Coupon.'}}</span>

                        <v-divider class="mt-5 mb-5"></v-divider>
                        <div>
                          <v-checkbox
                            v-model="form.subscribeCheck"
                            label="Yes! I would like to stay updated with the latest bread news."
                          >
                          </v-checkbox>
                          <template v-if="!isLoading">
                            <v-btn
                              v-if="isAllFieldsFilled"
                              color="primary"
                              block
                              large
                              @click="placeOrder"
                            > Place Order </v-btn>
                            <v-btn
                              v-else
                              color="primary"
                              block
                              large
                              disabled
                            > Place Order </v-btn>
                          </template>
                          <template v-else>
                            <v-btn
                              v-show="isLoading"
                              block
                              large
                              disabled
                            > Processing your order... </v-btn>
                            <v-progress-linear
                              v-show="isLoading"
                              indeterminate
                              color="primary"
                            ></v-progress-linear>
                          </template>
                          <span class="text-caption"> {{ isAllFieldsFilled ? "" : "Please fill in all fields before placing an order!"}} </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </div>

              </v-row>
            </v-container>
          </v-card>
          <!-- card ends -->
        </v-col>
      </v-row>
    </v-container>

    <v-sheet
      v-scrollanimation
      id="signup"
      color="#F9F7F8"
      height="300"
      class="mx-auto pt-15 mt-10 pr-3 pl-3"
      align="center"
    >
      <span
        class="font-weight-bold"
        :class="isMobile ? 'text-h5' : 'text-h4'"
      >Want product news and updates?</span> <br />
      <span
        v-if="!isMobile"
        class="text-h6 font-weight-light"
      >Sign up to our newsletter to stay up to date.</span>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          :cols="isMobile ? 10 : 3"
          :class="{'d-flex flex-row align-center mt-10': !isMobile , 'mt-5' : isMobile}"
        >
          <v-text-field
            v-model="subscribers.email"
            placeholder="Enter your email"
            outlined
            hide-details
            :class="{'mr-2': !isMobile, 'mb-2' : isMobile}"
            dense
          ></v-text-field>
          <v-btn
            color="primary"
            @click="subscribe"
          >Notify Me</v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-sheet>

    <v-sheet
      height="120"
      class="mx-auto pt-15"
      align="center"
    >
      <span> &#169; {{ new Date().getFullYear() }} Bread Sydney. All rights reserved.</span> <br />
      <span>Designed and built by </span>
      <a href="https://lauracao.dev/">Laura</a>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'OrderForm',

  data: () => ({
    coupon: "",
    isCouponValid: false,
    isCouponEntered: false,
    datesCountObj: {},
    price: 12,
    deliveryPrice: 10,
    menu: false,
    isDialogShowing: false,
    isLoading: false,
    form: {
      first: "",
      last: "",
      email: "",
      mobile: "",
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      deliveryMethod: null,
      deliveryStreet: "",
      deliverySuburb: "",
      deliveryPostCode: "",
      paymentMethod: null,
      quantity: 1,
      subscribeCheck: true,
    },
    subscribers: {
      email: "",
    },
  }),

  computed: {
    isOneLeft() {
      if (this.datesCountObj[this.form.date] === 1) {
        return true
      }
    },
    minDate() {
      let today = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
      today.setDate(today.getDate() + 3);
      return today.toISOString().slice(0, 10)
    },
    totalCost() {
      return this.formatCurrency(this.form.deliveryMethod === 'delivery' ? this.deliveryPrice + this.form.quantity * this.price : this.form.quantity * this.price)
    },
    deliveryCost() {
      return this.form.deliveryMethod === 'delivery' ? this.formatCurrency(this.deliveryPrice) : "-"
    },
    isAllFieldsFilled() {
      if (this.form.first && this.form.last && this.form.email && this.form.mobile && this.form.deliveryStreet && this.form.deliverySuburb && this.form.deliveryPostCode) {
        return true
      } else {
        return false
      }
    },
    isMobile() {
      return (this.$vuetify.breakpoint.smAndDown)
    }
  },

  methods: {
    allowedDates(val) {
      let unavailableDatesArray = Object.keys(this.datesCountObj).filter(key => this.datesCountObj[key] === 2)
      let weekDay = new Date(val).getDay()

      if (this.form.deliveryMethod === 'delivery') {
        return (weekDay === 0 && unavailableDatesArray.indexOf(val) === -1 || weekDay === 6 && unavailableDatesArray.indexOf(val) === -1)
      } else {
        return unavailableDatesArray.indexOf(val) === -1
      }
    },
    checkCoupon() {
      console.log(this.coupon)
      if (this.coupon === 'FIRSTTIME') {
        this.price = 2
        this.deliveryPrice = 0
        this.isCouponEntered = true
        this.isCouponValid = true
      } else {
        this.price = 12
        this.deliveryPrice = 10
        this.isCouponEntered = true
        this.isCouponValid = false
      }
    },
    placeOrder() {
      this.isLoading = true
      fetch(`${process.env.VUE_APP_API_URL}`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          this.form.id = data
          this.$router.push(`/order/${data}`)
        });
    },

    formatCurrency(val) {
      let formatter = new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        minimumFractionDigits: 2,
      });
      return formatter.format(val);
    },

    subscribe() {
      console.log(this.subscribers)
    }
  },

  mounted() {
    fetch(`${process.env.VUE_APP_API_URL}-dates`)
      .then(async (response) => {
        if (response.ok) {
          let data = await response.json();
          this.datesCountObj = data
          // console.log(this.datesCountObj)
        } else {
          if (response.status === 404) {
            console.log('error occurred')
          }
          throw "error";
        }
      })
      .catch(() => {
        console.log("ERROR");
      });
  }
}

</script>

<style scoped>
/*
  This classes are for the directive. 
  For each element observed by our directive, the before-enter class is added.
*/
.before-enter {
  opacity: 0;
  transform: translateY(50px);
  transition: all 1s ease-out;
}
/* 
  If the element intersects with the viewport, the before-enter class is added.
*/
.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
