<template>
  <v-app>
    <v-app-bar
      class="pl-5 pr-5"
      app
      color="#F9F7F8"
    >
      <img src="../assets/bread.sydney.svg" height="22rem">
      <v-spacer></v-spacer>
      <v-tabs right>
        <v-tab @click="jumpToOrder">Order</v-tab>
        <v-tab @click="jumpToSignup" v-if="!$vuetify.breakpoint.xs">Signup</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
// import Home from './components/Home';

export default {
  name: 'MainLayout',

  methods: {
    jumpToOrder() {
      window.location.hash = "order";
    },
    jumpToSignup() {
      window.location.hash = "signup";
    },
  }
};
</script>
