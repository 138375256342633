<template>
  <v-container>

    <v-container v-if="form">
      <v-img
        v-if="isMobile"
        src="../assets/order-details-cropped.jpg"
        alt="baking-setup"
        max-height="100"
      ></v-img>
      <v-row class="ma-1">
        <v-col v-if="!isMobile">
          <!-- :src=" isMobile? '../assets/order-details-cropped.jpg':'../assets/order-details.jpg'" -->
          <v-img
            src="../assets/order-details.jpg"
            alt="baking-setup"
            max-width="600"
          ></v-img>
        </v-col>
        <v-col>
          <span class="text-h3 font-weight-bold">Thanks for ordering</span> <br /> <br />
          <span>This page contains your order details. We'll send you an email with this website link so you have a copy in your inbox as well.</span> <br /> <br />
          <span class="font-weight-bold">Order Status: </span>

          <span
            v-if="form.paymentMethod === 'cash'"
            class="font-weight-bold orange--text text--lighten-2"
          > {{ `cash payment to be made on ${form.date}` }}</span>

          <span
            v-if="form.paymentMethod === 'transfer'"
            class="font-weight-bold text--lighten-2"
            v-bind:class=" form.isPaymentConfirmed ? 'green--text' : 'orange--text' "
          > {{ form.isPaymentConfirmed ? "payment confirmed" : "payment to be confirmed" }}</span>

          <v-card
            v-if="form.paymentMethod === 'transfer' && form.isPaymentConfirmed === false"
            outlined
            color="#F9F7F8"
          >
            <v-card-text>{{ `Please transfer ${totalCost} to the below payID. Your order will be confirmed once we manually check the transfer` }} <br />
              <span class="font-weight-bold text-subtitle-1"> payID: 0410967625</span> <br />
            </v-card-text>
          </v-card>

          <br />
          <span class="font-weight-bold">Order Id: </span>
          <span class="font-weight-bold grey--text">{{$route.params.id}}</span> <br />

          <v-row class="mt-3">
            <v-col cols="3">
              <v-img
                src="../assets/bread-item.svg"
                alt="bread-item"
                contain
                width="120"
              ></v-img>
            </v-col>
            <v-col>
              <span class="font-weight-bold">Sourdough (700g)</span> <br />
              <span class="font-weight-light grey--text text--darken-2">Quantity: {{form.quantity}}</span>
              <br /> <br /> <br />
            </v-col>
            <v-col class="d-flex align-start">
              <span
                style="width: 100%"
                class="font-weight-bold text-right"
              >{{formatCurrency(form.quantity * this.price)}}</span>
            </v-col>
          </v-row>
          <v-divider class="mt-5 mb-5"></v-divider>
          <v-row>
            <v-col>
              <div class="d-flex flex-row align-center font-weight-light mt-1">
                <span style="width: 50%">Subtotal</span>
                <span
                  style="width: 50%"
                  class="text-right font-weight-bold"
                >
                  {{formatCurrency(form.quantity * this.price)}}
                </span>
              </div>
              <div class="d-flex flex-row align-center font-weight-light mt-3">
                <span style="width: 50%"> Delivery</span>
                <span
                  style="width: 50%"
                  class="text-right font-weight-bold"
                >
                  {{ deliveryCost }}
                </span>
              </div>

              <v-divider class="mt-5 mb-5"></v-divider>

              <div class="d-flex flex-row align-center font-weight-bold mt-3 text-h6">
                <span style="width: 50%"> Total</span>
                <span
                  style="width: 50%"
                  class="text-right"
                >
                  {{ totalCost }}
                </span>
              </div>

              <v-row class="mt-5 font-weight-light grey--text text--darken-2">
                <v-col :class="isMobile? '' : 'd-flex flex-row'">
                  <div :style=" isMobile? '': 'width:40%'">
                    <span class="font-weight-bold">Order for:</span> <br />
                    {{form.first}} {{form.last}} <br />
                    {{form.email}} <br />
                    {{form.mobile}} <br /> <br />
                  </div>
                  <div :style=" isMobile? '': 'width:40%'">
                    <span class="font-weight-bold">Delivery to:</span> <br />
                    Date: {{form.date}} <br />
                    Method: {{form.deliveryMethod}} <br />
                    Address: {{form.deliveryStreet}}, {{form.deliverySuburb}}, {{form.deliveryPostCode}}<br /> <br />
                  </div>
                  <div :style=" isMobile? '': 'width:20%'">
                    <span class="font-weight-bold">Payment:</span> <br />
                    {{form.paymentMethod}}
                  </div>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-if="!form"
      style="height: 100vh"
      fill-height
    >
      <v-row justify="center">
        <v-col align="center">
          <div class="text-h6 grey--text mb-5">
            Loading your order...
          </div>
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="loadingValue"
            color="primary"
          >
            {{ loadingValue }}
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
export default {
  name: 'Order',

  data: () => ({
    test: false,
    form: null,
    price: 12,
    deliveryPrice: 10,
    loadingInterval: {},
    loadingValue: 0,
  }),

  computed: {
    totalCost() {
      return this.formatCurrency(this.form.deliveryMethod === 'delivery' ? this.deliveryPrice + this.form.quantity * this.price : this.form.quantity * this.price)
    },
    deliveryCost() {
      return this.form.deliveryMethod === 'delivery' ? this.formatCurrency(this.deliveryPrice) : "-"
    },
    isMobile() {
      return (this.$vuetify.breakpoint.mdAndDown)
    },
  },


  methods: {
    formatCurrency(val) {
      let formatter = new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        minimumFractionDigits: 2,
      });
      return formatter.format(val);
    },
  },

  mounted() {
    let id = this.$route.params.id
    console.log(`${process.env.VUE_APP_API_URL}/${id}`)

    fetch(`${process.env.VUE_APP_API_URL}/${id}`)
      .then(async (response) => {
        if (response.ok) {
          let data = await response.json();
          this.form = data
        } else {
          if (response.status === 404) {
            console.log('error occurred')
          }
          throw "error";
        }
      })
      .catch(() => {
        console.log("ERROR");
      });

    // for loader
    this.loadingInterval = setInterval(() => {
      if (this.loadingValue === 100) {
        return (this.loadingValue = 0)
      }
      this.loadingValue += 10
    }, 1000)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

}
</script>

<style>
</style>